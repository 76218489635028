import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, MenuItem, Switch, TextField } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { styled } from '@mui/material/styles';
import books from '../../../shared/books';
import { useAuth } from '../../../providers/AuthProvider';
import _protected from '../../../shared/protected';
import item from '../../../shared/item';


import EditIcon from '@mui/icons-material/Edit';
import LazyImg from '../../public/LazyImg';
import Chips from '../../public/Chips';
import UnfTable from '../../public/UnfTable';


const Input = styled('input')({
    display: 'none',
});

const currencies = [
    {
        value: '',
        label: 'Не указано',
    },
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'RUB',
        label: '₽',
    }
];

const AdminCatalog = ({ onSelect, selectItems }) => {
    let auth = useAuth();
    console.log('rerender AdminCatalog');
    const [taskId, setTaskId] = useState(null);
    const [catalog, setCatalog] = useState([]);
    const [deleting, setDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [selected, setSelected] = useState([]);
    const [state, setState] = useState({
        dataBook: {},
        get: (name) => {
            if (state.dataBook[name] === undefined) {
                books.getBook(auth.token, name).then(value => {
                    let _sate = state;
                    _sate.dataBook[name] = value.result;
                    setState(_sate);
                });
                return [];
            }
            return state.dataBook[name];
        }
    });
    const [form, setForm] = useState({
        series: [],
        unf: [],
        cover: {
            text: "", files: []
        },
        screen: {
            text: "", files: []
        },
        secret: {
            text: "", files: []
        },
        preview: {
            text: "", files: []
        }
    });

    const [inputs, setInputs] = useState({});

    const catColumns = [
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Edit',
            width: 60,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => handleEditClick(id)}
                        color="inherit"
                    />
                ];
            }
        }, {
            field: 'cover',
            headerName: 'Обложка',
            renderCell: (params) => {
                let { row } = params;
                let { cover } = row;
                if (cover.files.length > 0) {
                    return <LazyImg fileId={cover.files[0].regId} />
                }
                return "";
            },
            filterable: false
        }, {
            field: 'name',
            headerName: 'Наименование/Name',
            width: 300
        }, {
            field: 'brands',
            headerName: 'Бренд/Brand',
            width: 300,
            renderCell: (params) => {
                let { row } = params;
                return <Chips data={row.brands} />;
            },
            valueGetter: (value, row, column) => {
                if (row.brands) {
                    return row.brands.map(v => {
                        return v.label;
                    }).join(',');
                } else {
                    return ""
                }
            }
        }, {
            field: 'projects',
            headerName: 'Проект/Project',
            width: 300,
            renderCell: (params) => {
                let { row } = params;
                return <Chips data={row.projects} />;
            },
            valueGetter: (value, row, column) => {
                if (row.projects) {
                    return row.projects.map(v => {
                        return v.label;
                    }).join(',');
                } else {
                    return ""
                }
            }
        }, {
            field: 'source',
            headerName: 'Наименование объекта / Object name',
            width: 300,
            renderCell: (params) => {
                let { row } = params;
                return <Chips data={row.source} />;
            },
            valueGetter: (value, row, column) => {
                let sources = [];
                if (row.source) {
                    sources = row.source.map(element => {
                        return element.label || element;
                    });
                    sources = sources.filter((v, i, a) => i === a.indexOf(v));
                }
                return sources.join(',');
            }
        }, {
            field: 'serial',
            headerName: 'Серийный',
            width: 300,
            renderCell: (params) => {
                let { row } = params;
                return (row.serial) ? "Да" : "Нет";
            },
            valueGetter: (value, row, column) => {
                return (row.serial) ? "Да" : "Нет";
            }
        }, {
            field: 'producer',
            headerName: 'Производитель / Producer',
            width: 300,
            renderCell: (params) => {
                let { row } = params;
                return <Chips data={row.producer} />;
            },
            valueGetter: (value, row, column) => {
                if (row.producer) {
                    return row.producer.map(v => {
                        return v.label;
                    }).join(',');
                } else {
                    return ""
                }
            }
        }, {
            field: 'country',
            headerName: 'Страна производитель / Country',
            width: 300,
            renderCell: (params) => {
                let { row } = params;
                return <Chips data={row.country} />;
            },
            valueGetter: (value, row, column) => {
                if (row.country) {
                    return row.country.map(v => {
                        return v.label;
                    }).join(',');
                } else {
                    return ""
                }
            }
        }, {
            field: 'materials',
            headerName: 'Тип материалов (фильм, сезон и т.д.)',
            width: 300,
            renderCell: (params) => {
                let { row } = params;
                return <Chips data={row.materials} />;
            },
            valueGetter: (value, row, column) => {
                if (row.materials) {
                    return row.materials.map(v => {
                        return v.label;
                    }).join(',');
                } else {
                    return ""
                }
            }
        }, {
            field: 'year',
            headerName: 'Год производства / Year',
            width: 300,
            renderCell: (params) => {
                let { row } = params;
                return <Chips data={row.year} />;
            },
            valueGetter: (value, row, column) => {
                if (row.year) {
                    return row.year.map(v => {
                        return v.label;
                    }).join(',');
                } else {
                    return ""
                }
            }
        }, {
            field: 'currency',
            headerName: 'Валюта / Currency',
            width: 100
        }, {
            field: 'tags',
            headerName: 'Тэги / Tags',
            width: 300,
            renderCell: (params) => {
                let { row } = params;
                return <Chips data={row.tags} />;
            },
            valueGetter: (value, row, column) => {
                if (row.tags) {
                    return row.tags.map(v => {
                        return v.label;
                    }).join(',');
                } else {
                    return ""
                }
            }
        }, {
            field: 'url',
            headerName: 'Ссылка на продукт Riki Group',
            width: 300,
            renderCell: (params) => {
                let { row } = params;
                if (!row.url) {
                    return "";
                }
                return <TextField
                    {...params}
                    maxRows={3}
                    multiline
                    fullWidth
                    defaultValue={row.url}
                    InputProps={{
                        readOnly: true,
                    }} />
            }
        }
    ];

    const loadCatalog = useCallback(() => {
        if (taskId) {
            return _protected.load(auth.token, 'items', '', { taskId: taskId }).then(v => {
                if (v.status === 'ok') {
                    setCatalog(v.result);
                }
            });
        } else {
            return _protected.load(auth.token, 'items', '').then(v => {
                if (v.status === 'ok') {
                    setCatalog(v.result);
                }
            });
        }
    }, [auth.token]);

    useEffect(() => {
        if (!open) {
            setForm({
                cover: {
                    text: "", files: []
                },
                screen: {
                    text: "", files: []
                },
                secret: {
                    text: "", files: []
                },
                preview: {
                    text: "", files: []
                }
            });
            setInputs({});
        }
    }, [open])

    useEffect(() => {
        if (window.BX24) {
            let info = window.BX24.placement.info();
            switch (info.placement) {
                case "TASK_VIEW_TAB":
                    setTaskId(info.options.taskId);
                    break;
            }
        }
        loadCatalog();
    }, [loadCatalog]);

    useEffect(() => {
        // if (onSelect && selected.length > 0) {
        //     let _items = selected.map((v, i, a) => {
        //         return catalog.find((item) => {
        //             return item._id === v;
        //         })
        //     })
        //     onSelect(_items);
        // }
        if (onSelect) {
            onSelect(selected);
        }
    }, [selected])

    const handleClickOpen = () => {
        setForm({
            cover: {
                text: "", files: []
            },
            screen: {
                text: "", files: []
            },
            secret: {
                text: "", files: []
            },
            preview: {
                text: "", files: []
            }
        });
        setOpen(true);
    };

    const handleEditClick = (id) => {
        let _form = catalog.find(v => {
            return v._id == id;
        });
        console.log(_form);
        setForm({ ...form, ..._form });
        setEdit(true);
        setOpen(true);
    }

    const handleClickDelete = () => {
        setDelete(true);
    }

    const handleDelete = async () => {
        for (let i = 0; i < selected.length; i++) {
            await _protected.delete(auth.token, 'items', selected[i]);
        }
        loadCatalog().then(() => {
            setDelete(false);
        });
    }

    const handleClose = () => {
        setEdit(false);
        setOpen(false);
    };

    const loadFile = async (files) => {
        let _files = [];
        for (let file of files) {
            _files.push({
                data: file,
                name: file.name,
                type: file.type || 'application/octet-stream',
                size: file.size
            });
        }
        return _files;
    }

    const syncInputs = () => {
        let current = { ...form };
        for (const [key, value] of Object.entries(inputs)) {
            if (value) {
                let book = state.get(key);
                let replace = book.find((v) => {
                    return v.label.toUpperCase() == value.toUpperCase();
                });
                if (current[key]) {
                    if (Array.isArray(current[key])) {
                        current[key].push(replace || value);
                    }
                } else {
                    current[key] = [replace || value];
                }
            }
        }
        return current;
    }

    const handleAdd = async (event) => {
        let response;
        let syncForm = syncInputs();
        if (taskId) {
            response = await item.addCatElement(auth.token, { ...syncForm, taskId: taskId });
        } else {
            response = await item.addCatElement(auth.token, syncForm);
        }
        if (response.status === 'ok') {
            loadCatalog().then(() => {
                setOpen(false);
            })
        }
    }

    const handleSave = async (event) => {
        let syncForm = syncInputs();
        let response = await item.saveCatElement(auth.token, syncForm);
        if (response.status === 'ok') {
            loadCatalog().then(() => {
                setEdit(false);
                setOpen(false);
            });
        }
    }

    const updatePreview = (event) => {
        loadFile(event.target.files).then(value => {
            let name = value.map((item) => {
                return item.name
            }).join(', ');
            setForm({
                ...form, preview: {
                    text: name, files: value
                }
            })
        });
    }

    const updateSecretFile = (event) => {
        loadFile(event.target.files).then(value => {
            let name = value.map((item) => {
                return item.name
            }).join(', ');
            setForm({
                ...form, secret: {
                    text: name, files: value
                }
            })
        });
    }

    const updateScreen = (event) => {
        loadFile(event.target.files).then(value => {
            let name = value.map((item) => {
                return item.name
            }).join(', ');
            setForm({
                ...form, screen: {
                    text: name, files: value
                }
            })
        });
    }

    const updateCover = (event) => {
        loadFile(event.target.files).then(value => {
            let name = value.map((item) => {
                return item.name
            }).join(', ');
            setForm({
                ...form, cover: {
                    text: name, files: value
                }
            })
        });
    }

    const saveUnf = (unf) => {
        setForm({
            ...form, unf: unf
        })
    }

    return [
        !selectItems && <Box component="div" sx={{ margin: '10px 5px' }}>
            <Button size='small' onClick={handleClickOpen} variant="contained">Добавить</Button>
            {selected.length > 0 && <Button size='small' onClick={handleClickDelete} variant="contained" color="error" sx={{ ml: 1 }}>Удалить</Button>}
        </Box>,
        <DataGridPro
            disableRowSelectionOnClick
            getRowId={(row) => row._id}
            getRowHeight={() => 'auto'}
            showCellRightBorder
            rows={catalog}
            columns={catColumns}
            slots={{
                toolbar: GridToolbar,
            }}
            onRowSelectionModelChange={(selection) => setSelected(() => selection)}
            rowSelectionModel={selected}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            pagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            initialState={{
                pagination: {
                    pageSize: 25
                }
            }}
        />,
        <Dialog
            open={open}
            aria-labelledby="responsive-dialog-title"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="responsive-dialog-title">
                {!edit && "Добавление нового элемента."}
                {edit && "Редактирование элемента."}
            </DialogTitle>
            <DialogContent>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            name="name"
                            label="Наименование/Name"
                            type="text"
                            id="name"
                            multiline
                            defaultValue={form.name}
                            onChange={(e) => {
                                setForm({ ...form, name: e.target.value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            name="cover"
                            value={form.cover.text}
                            label="Обложка"
                            type="text"
                            id="cover"
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ margin: "0px 20px" }}>
                            <label htmlFor="cover-file">
                                <Input accept="image/*" id="cover-file" onChange={(e) => updateCover(e)} multiple type="file" />
                                <Button variant="contained" component="span">
                                    Выбрать
                                </Button>
                            </label>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={6}>
                        <TextField
                            fullWidth
                            name="screen"
                            value={form.screen.text}
                            label="Скрины"
                            type="text"
                            id="screen"
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ margin: "0px 20px" }}>
                            <label htmlFor="screen-file">
                                <Input accept="image/*" id="screen-file" onChange={(e) => updateScreen(e)} multiple type="file" />
                                <Button variant="contained" component="span">
                                    Выбрать
                                </Button>
                            </label>
                        </Box>
                    </Grid> */}
                    <Grid item xs={6}>
                        <Autocomplete
                            multiple
                            freeSolo
                            disablePortal
                            defaultValue={form.brands ? form.brands : []}
                            options={state.get('brands')}
                            renderInput={(params) => <TextField {...params} label="Бренд / Brand" />}
                            onInputChange={(e, v) => {
                                setInputs({ ...inputs, brands: v });
                            }}
                            onChange={(e, v) => {
                                if (v) {
                                    setForm({ ...form, brands: v });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            multiple
                            freeSolo
                            disablePortal
                            defaultValue={form.projects ? form.projects : []}
                            options={state.get('projects')}
                            renderInput={(params) => <TextField {...params} label="Проект/Project" />}
                            onInputChange={(e, v) => {
                                setInputs({ ...inputs, projects: v });
                            }}
                            onChange={(e, v) => {
                                if (v) {
                                    setForm({ ...form, projects: v });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            multiple
                            freeSolo
                            disablePortal
                            defaultValue={form.source ? form.source : []}
                            options={state.get('source')}
                            renderInput={(params) => <TextField {...params} label="Наименование объекта/Object Name" />}
                            onInputChange={(e, v) => {
                                setInputs({ ...inputs, source: v });
                            }}
                            onChange={(e, v) => {
                                if (v) {
                                    setForm({ ...form, source: v });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Switch checked={form.serial} onChange={(e) => {
                                    setForm({ ...form, serial: e.target.checked });
                                }} name="serial" />
                            }
                            label="Серийный"
                        />
                    </Grid>
                    { form.serial && <Grid item xs={12}>
                        <UnfTable unf={form.unf} onSave={saveUnf} />
                    </Grid> }
                    <Grid item xs={6}>
                        <Autocomplete
                            multiple
                            freeSolo
                            disablePortal
                            defaultValue={form.materials ? form.materials : []}
                            options={state.get('materials')}
                            renderInput={(params) => <TextField {...params} label="Тип материалов (фильм, сезон и т.д.)" />}
                            onInputChange={(e, v) => {
                                setInputs({ ...inputs, materials: v });
                            }}
                            onChange={(e, v) => {
                                if (v) {
                                    setForm({ ...form, materials: v });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            multiple
                            freeSolo
                            disablePortal
                            defaultValue={form.producer ? form.producer : []}
                            options={state.get('producer')}
                            renderInput={(params) => <TextField {...params} label="Производитель/Producer" />}
                            onInputChange={(e, v) => {
                                setInputs({ ...inputs, producer: v });
                            }}
                            onChange={(e, v) => {
                                if (v) {
                                    setForm({ ...form, producer: v });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            multiple
                            freeSolo
                            disablePortal
                            defaultValue={form.country ? form.country : []}
                            options={state.get('country')}
                            renderInput={(params) => <TextField {...params} label="Страна производитель/Country" />}
                            onInputChange={(e, v) => {
                                setInputs({ ...inputs, country: v });
                            }}
                            onChange={(e, v) => {
                                if (v) {
                                    setForm({ ...form, country: v });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            multiple
                            freeSolo
                            disablePortal
                            defaultValue={form.year ? form.year : []}
                            options={state.get('year')}
                            renderInput={(params) => <TextField {...params} label="Год производства/Year" />}
                            onInputChange={(e, v) => {
                                setInputs({ ...inputs, year: v });
                            }}
                            onChange={(e, v) => {
                                if (v) {
                                    setForm({ ...form, year: v });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="currency"
                            label="Валюта / Currency"
                            select
                            defaultValue={form.currency ? form.currency : ""}
                            onChange={(e) => {
                                setForm({ ...form, currency: e.target.value });
                            }}
                            sx={{ width: '100%' }}>
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {/* <Grid item xs={6}>
                        <TextField
                            id="fee"
                            label="Лицензионный сбор/License Fee"
                            type="text"
                            multiline
                            defaultValue={form.fee ? form.fee : ""}
                            onChange={(e) => {
                                setForm({ ...form, fee: e.target.value });
                            }}
                            sx={{ width: '100%' }}
                        />
                    </Grid> */}
                    <Grid item xs={6}>
                        <Autocomplete
                            multiple
                            freeSolo
                            disablePortal
                            defaultValue={form.tags ? form.tags : []}
                            options={state.get('tags')}
                            renderInput={(params) => <TextField {...params} label="Тэги/Tags" />}
                            onInputChange={(e, v) => {
                                setInputs({ ...inputs, tags: v });
                            }}
                            onChange={(e, v) => {
                                if (v) {
                                    setForm({ ...form, tags: v });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            name="url"
                            label="Ссылка на продукт Riki Group"
                            type="text"
                            id="url"
                            multiline
                            defaultValue={form.url}
                            onChange={(e) => {
                                setForm({ ...form, url: e.target.value });
                            }}
                        />
                    </Grid>
                    {form.serial && <Grid item xs={12}>

                    </Grid>}
                    {/* <Grid item xs={6}>
                        <TextField
                            fullWidth
                            name="cover"
                            value={form.preview.text}
                            multiline
                            label="Preview video"
                            type="text"
                            id="cover"
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ margin: "0px 20px" }}>
                            <label htmlFor="preview-file">
                                <Input accept="video/mpeg,video/mp4" onChange={(e) => updatePreview(e)} id="preview-file" multiple type="file" />
                                <Button variant="contained" component="span">
                                    Выбрать
                                </Button>
                            </label>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            name="cover"
                            value={form.secret.text}
                            multiline
                            label="Лицензионный материал"
                            type="text"
                            id="cover"
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ margin: "0px 20px" }}>
                            <label htmlFor="secret-file">
                                <Input accept="*" id="secret-file" onChange={(e) => updateSecretFile(e)} multiple type="file" />
                                <Button variant="contained" component="span">
                                    Выбрать
                                </Button>
                            </label>
                        </Box>
                    </Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                {!edit && <Button onClick={handleAdd}>
                    Добавить
                </Button>}
                {edit && <Button onClick={handleSave}>
                    Сохранить
                </Button>}
                <Button onClick={handleClose} autoFocus>
                    Отменить
                </Button>
            </DialogActions>
        </Dialog>,
        <Dialog open={deleting} aria-describedby="alert-dialog-slide-description">
            <DialogTitle>{"Удаление объектов."}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Подтвердите удаление.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDelete(false)}>Нет</Button>
                <Button onClick={() => handleDelete()}>Да</Button>
            </DialogActions>
        </Dialog>
    ];
}

export default AdminCatalog;