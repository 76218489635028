import { GridRowModes, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import _protected from '../../../shared/protected';
import BookControl from '../../../providers/BookControl';
import user from '../../../shared/user';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';

function AdminUsers() {
  let auth = useAuth();

  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({});
  const [rowModesModel, setRowModesModel] = useState({});
  const [users, setUser] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadUsers = () => {
    _protected.load(auth.token, 'cell_users').then(v => {
      if (v.status === 'ok') {
        setUser(v.result);
      }
    });
  }
  const userColumns = [{
    field: 'email',
    headerName: 'email',
    width: 150,
    editable: true,
  }, {
    field: 'firstname',
    headerName: 'Имя',
    width: 150,
    editable: true,
  }, {
    field: 'lastname',
    headerName: 'Фамилия',
    type: 'string',
    width: 110,
    editable: true
  }, {
    field: 'active',
    headerName: 'Active',
    type: 'boolean',
    width: 110
  }, {
    field: 'bxId',
    headerName: 'Bitrix ID',
    type: 'number',
    editable: true,
    width: 110
  }, {
    field: 'role',
    headerName: 'Role',
    width: 200,
    renderCell: (params) => {
      let { row } = params;
      return <BookControl
        value={row.perm}
        _id={row._id}
        bookName="cell_perms"
        withEmpty={true}
        sx={{ minWidth: 190 }}
        onChange={(value) => user.updatePerm(auth.token, row._id, value)}></BookControl>
    }
  }, {
    field: 'group',
    headerName: 'Group',
    width: 200,
    renderCell: (params) => {
      let { row } = params;
      return <BookControl
        value={row.group}
        _id={row._id}
        bookName="groups"
        withEmpty={true}
        sx={{ minWidth: 190 }}
        onChange={(value) => user.updateGroup(auth.token, row._id, value)}></BookControl>
    }
  }, {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onClick={() => handleSaveClick(id)}
            color="primary"
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={() => handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    }
  }];

  const processRowUpdate = (row) => {
    _protected.update(auth.token, 'cell_users', {
      _id: row._id
    }, {
      $set: {
        email: row.email,
        firstname: row.firstname,
        lastname: row.lastname,
        bxId: row.bxId
      }
    });
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => {
    _protected.delete(auth.token, 'cell_users', id).then((res) => {
      if (res.status === 'ok') {
        setUser(users.filter((row) => row._id !== id));
      }
    });
  };

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = users.find((row) => row._id === id);
    if (editedRow.isNew) {
      setUser(users.filter((row) => row._id !== id));
    }
  }

  const addUser = () => {
    _protected.add(auth.token, 'cell_users', {
      ...form, active: true, confirmed: true
    }).then(() => {
      setOpen(false);
      setForm({});
      setLoading(true);
    });
  }

  useEffect(() => {
    loadUsers();
    setLoading(false);
  }, [loading]);

  return (
    [<Box component="div" sx={{ margin: '10px 5px' }}>
      <Button size='small' onClick={() => setOpen(true)} variant="contained">Добавить</Button>
    </Box>,
    <DataGridPro
      getRowId={(row) => row._id}
      showCellRightBorder
      rows={users}
      columns={userColumns}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      checkboxSelection
      components={{ Toolbar: GridToolbar }}
      rowModesModel={rowModesModel}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      experimentalFeatures={{ newEditingApi: true }}
      disableSelectionOnClick
    />,
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="responsive-dialog-title">
        {"Добавление пользователя."}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              type="text"
              onChange={(e) => setForm({...form, email: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Имя"
              type="text"
              onChange={(e) => setForm({...form, firstname: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Фамилия"
              type="text"
              onChange={(e) => setForm({...form, lastname: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Bitrix ID"
              type="number"
              onChange={(e) => setForm({...form, bxId: (e.target.value)?parseInt(e.target.value): "" })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => addUser()}>
          Добавить
        </Button>
        <Button onClick={() => setOpen(false)} autoFocus>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>]
  );
}

export default AdminUsers;