import { Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";

import Header from "../public/Header";
import AdminBooks from "./page/AdminBooks";
import AdminCatalog from "./page/AdminCatalog";
import AdminPerms from "./page/AdminPerms";
import AdminUsers from "./page/AdminUsers";


function Admin(props) {
    let navigate = useNavigate();
    return (
        <div className="adminPage" style={{ display: "flex" }}>
            <Box sx={{ minWidth: '200px' }} className="menu" >
                <Header sx={{ width: 100, margin: 'auto' }} />
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/catalog")}>
                            <ListItemText primary="Каталог" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/users")}>
                            <ListItemText primary="Пользователи" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/groups")}>
                            <ListItemText primary="Группы" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/perms")}>
                            <ListItemText primary="Системные права" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/status")}>
                            <ListItemText primary="Статусы" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/direct")}>
                            <ListItemText primary="Прямой доступ" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/brands")}>
                            <ListItemText primary="Бренды" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/season")}>
                            <ListItemText primary="Наименование" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/eps")}>
                            <ListItemText primary="Эпизоды" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/projects")}>
                            <ListItemText primary="Проекты" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/materials")}>
                            <ListItemText primary="Типы материалов" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/producer")}>
                            <ListItemText primary="Производитель" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/country")}>
                            <ListItemText primary="Страны производства" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/liccountry")}>
                            <ListItemText primary="Страны проката" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/tags")}>
                            <ListItemText primary="Тэги" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/year")}>
                            <ListItemText primary="Года" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/licensors")}>
                            <ListItemText primary="Лицензиар" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/clients")}>
                            <ListItemText primary="Покупатель" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/ifta")}>
                            <ListItemText primary="IFTA" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/rights")}>
                            <ListItemText primary="Rights" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/territories")}>
                            <ListItemText primary="Территории" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/platform")}>
                            <ListItemText primary="Тип проката" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/langdoc")}>
                            <ListItemText primary="Язык документов" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/lang")}>
                            <ListItemText primary="Язык" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/excltype")}>
                            <ListItemText primary="Искл / Не" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/term")}>
                            <ListItemText primary="Срок" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/payscheme")}>
                            <ListItemText primary="Схема оплаты" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/dubs")}>
                            <ListItemText primary="Доступ к дублированию" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/admin/manager")}>
                            <ListItemText primary="Ответственный" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <div className="content">
                <Routes>
                    <Route index path="*" element={<AdminCatalog />} />
                    <Route path="users" element={<AdminUsers />} />
                    <Route path="groups" element={<AdminBooks bookName="groups" />} />
                    <Route path="perms" element={<AdminPerms />} />
                    <Route path="direct" element={<AdminBooks bookName="direct" />} />
                    <Route path="brands" element={<AdminBooks bookName="brands" />} />
                    <Route path="season" element={<AdminBooks bookName="season" />} />
                    <Route path="eps" element={<AdminBooks bookName="eps" />} />
                    <Route path="ifta" element={<AdminBooks bookName="ifta" relate="rights" />} />
                    <Route path="rights" element={<AdminBooks bookName="rights" relate="ifta" />} />
                    <Route path="projects" element={<AdminBooks bookName="projects" />} />
                    <Route path="materials" element={<AdminBooks bookName="materials" />} />
                    <Route path="producer" element={<AdminBooks bookName="producer" />} />
                    <Route path="country" element={<AdminBooks bookName="country" />} />
                    <Route path="tags" element={<AdminBooks bookName="tags" />} />
                    <Route path="year" element={<AdminBooks bookName="year" />} />
                    <Route path="licensors" element={<AdminBooks bookName="licensors" />} />
                    <Route path="clients" element={<AdminBooks bookName="clients" />} />
                    <Route path="territories" element={<AdminBooks bookName="territories" relate="liccountry" />} />
                    <Route path="liccountry" element={<AdminBooks bookName="liccountry" />} />
                    <Route path="platform" element={<AdminBooks bookName="platform" />} />
                    <Route path="langdoc" element={<AdminBooks bookName="lang" />} />
                    <Route path="excltype" element={<AdminBooks bookName="excltype" />} />
                    <Route path="lang" element={<AdminBooks bookName="lang" />} />
                    <Route path="term" element={<AdminBooks bookName="term" />} />
                    <Route path="payscheme" element={<AdminBooks bookName="payscheme" />} />
                    <Route path="dubs" element={<AdminBooks bookName="dubs" />} />
                    <Route path="manager" element={<AdminBooks bookName="manager" />} />
                    <Route path="status" element={<AdminBooks bookName="status" />} />
                </Routes>
            </div>
        </div>
    );
}

export default Admin;