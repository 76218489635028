import './App.css';
import { LicenseInfo } from '@mui/x-license';

import {
  BrowserRouter,
  Routes,
  Route,
  React
} from "react-router-dom";
import Room from "./react/components//room/Room";
import SignIn from './react/components/auth/SignIn';
import SignUp from './react/components/auth/SignUp';
import Forgot from './react/components/auth/Forgot';
import Confirm from './react/components/auth/Confirm';
import Catalog from './react/components/Catalog';
import Admin from './react/components/private/Admin';
import { AuthProvider } from './react/providers/AuthProvider';
import Protected from './react/providers/Protected';
import Reset from './react/components/auth/Reset';
import RoomInternational from './react/components/room/page/RoomInternational';

LicenseInfo.setLicenseKey('0bba2ae5c41c1cef1eb13255165531c6Tz05NTc2MixFPTE3NTQ2NTg2NDgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==');

function App({ bxMode }) {
  console.log('render app')
  return (
    <AuthProvider bxMode={bxMode}>
      <BrowserRouter>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset/:code" element={<Reset />} />
          <Route path="/confirm/:code" element={<Confirm />} />
          <Route path='client/*' element={<Protected ret="client"><Room /></Protected>} />
          <Route path='admin/*' element={<Protected ret="admin"><Admin /></Protected>} />
          <Route index element={<Catalog />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
//<Route path='/catalog' element={<Catalog />} />