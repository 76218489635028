import config from "../../config";
import _protected from "./protected";

const chunk = 5 * 1024 * 1024;

const registerFile = async (token, file, chunkCount) => {
    let result = await fetch(config.url + '/files/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        },
        body: JSON.stringify({
            size: file.size,
            chunkSize: chunk,
            chunkCount: chunkCount,
            name: file.name,
            contentType: file.type
        })
    });
    if (result.ok) {
        result = await result.json();
        if (result.status == 'ok') {
            return result.result;
        }
    }
    return false;
}
const uploadChunk = async (token, data, file_id , chunkNumber, chunkCounts) => {
    let result = await fetch(config.url + '/files/chunks', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        },
        body: JSON.stringify({
            data: data,
            file_id: file_id,
            num: chunkNumber,
            count: chunkCounts
        })
    });
    if (result.ok) {
        result = await result.json();
        if (result.status == 'ok') {
            return true;
        }
    }
    return false;
}
const commitFile = async (token, file_id) => {
    let result = await fetch(config.url + '/files/end', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        },
        body: JSON.stringify({
            file_id: file_id
        })
    });
    if (result.ok) {
        result = await result.json();
        if (result.status == 'ok') {
            return result.result;
        }
    }
    return false;
}

export default {
    upload: (token, file) => {
        return new Promise(async (res, rej) => {
            if (file.size < chunk) {
                let file_id = await registerFile(token, file, 1);
                if (file_id) {
                    const reader = new FileReader();
                    reader.addEventListener("load", async () => {
                        console.log(reader.result);
                        await uploadChunk(token, reader.result, file_id, 1, 1);
                        await commitFile(token, file_id);
                        res(file_id);
                    }, false);
                    reader.readAsDataURL(file.data);
                }
            } else {
                let chunks = Math.ceil(file.size / chunk);
                let file_id = await registerFile(token, file, chunks);
                if (file_id) {
                    for (let i = 0; i < chunks; i++ ) {
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                            let result = await uploadChunk(token, reader.result, file_id, i + 1, chunks);
                            if (result && i + 1 == chunks) {
                                await commitFile(token, file_id);
                                res(file_id);
                            }
                        }, false);
                        reader.readAsDataURL(file.data.slice(chunk * i, chunk * i + chunk));
                    }
                }
            }
        });
    }
}